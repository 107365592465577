import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TextBlockHeader = props => (
  <div className={classNames(
    'marche-text-block-header',
  )}>
    <h1>{props.headerText}</h1>
  </div>
)

TextBlockHeader.propTypes = {
  headerText: PropTypes.object.isRequired,
}

export default TextBlockHeader
