import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import scrollToComponent from 'react-scroll-to-component'

import TwIcon from '../images/svg/social/tw-icon.svg'
import FbIcon from '../images/svg/social/fb-icon.svg'
import IgIcon from '../images/svg/social/insta-icon.svg'
import McIcon from '../images/svg/assets/logotype-mercari-w.svg'
import Copyright from '../images/svg/assets/copyright.svg'

const scrollOptions = {
  offset: -80,
  align: 'top',
  duration: 500,
  ease: 'in-out-expo',
}

const Footer = props => {
  const { header, menu, hideLinks, showNewLogoLink } = props
  if (menu === undefined) return null
  return (
    <footer>
      <div className={classNames('marche-footer', 'marche-row')}>
        <div className="marche-footer-links">
          {hideLinks ? null : (
            <div className="marche-footer-links-section">
              {menu.map((link, index) => {
                if (index === 0) link.ref = header
                return (
                  <a
                    className="marche-footer-links-section-item"
                    onClick={() => scrollToComponent(link.ref, scrollOptions)}
                    key={index}
                    target="_blank"
                  >
                    {link.svg}
                  </a>
                )
              })}
            </div>
          )}
          <div className="marche-footer-links-design">
            {showNewLogoLink ? <Link to="/">新ロゴ紹介ページ</Link> : null}
            <a
              href="https://note.mu/mercari_design"
              target="_blank"
              rel="noopener noreferrer"
            >
              デザインブログ
            </a>
            <a
              href="https://about.mercari.com/press/press-kit/mercari/"
              target="_blank"
              rel="noopener noreferrer"
            >
              メルカリブランドガイドライン
            </a>
          </div>
        </div>
        <div className="marche-footer-social">
          <a
            href="https://twitter.com/mercari_jp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwIcon />
          </a>
          <a
            href="https://www.facebook.com/mercarijp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FbIcon />
          </a>
          <a
            href="https://www.instagram.com/mercari_life/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IgIcon />
          </a>
        </div>
        <div className="marche-footer-corporate">
          <a
            href="https://about.mercari.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <McIcon />
          </a>
          <p className="marche-footer-corporate-copyright">
            <Copyright />
          </p>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  header: PropTypes.object,
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideLinks: PropTypes.bool.isRequired,
  showNewLogoLink: PropTypes.bool.isRequired,
}

export default Footer
