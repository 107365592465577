import React from 'react'
import PropTypes from 'prop-types'

import Head from './Head'
import Header from './Header'
import Footer from './Footer'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.header = React.createRef()
  }

  render() {
    const {
      children,
      headerLinks,
      footerLinks,
      hideLinks,
      showNewLogoLink,
    } = this.props
    return (
      <React.Fragment>
        <Head />
        <Header
          menu={headerLinks}
          ref={this.header}
          hideLinks={hideLinks}
          showNewLogoLink={showNewLogoLink}
        />
        <div className="marche-content-wrapper">{children}</div>
        <Footer
          header={this.header.current}
          menu={footerLinks}
          hideLinks={hideLinks}
          showNewLogoLink={showNewLogoLink}
        />
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  footerLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideLinks: PropTypes.bool,
  showNewLogoLink: PropTypes.bool,
}

Layout.defaultProps = {
  hideLinks: false,
  showNewLogoLink: false,
}

export default Layout
