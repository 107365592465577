import React from 'react'
import classNames from 'classnames'
import Reveal from 'react-reveal/Reveal'

import TextBlock from './TextBlock'
import VideoBlock from './VideoBlock'
import HeaderText from '../images/svg/text/head-motion.svg'

class Motion extends React.Component {
  render() {
    return (
      <div
        className={classNames('marche-content-item', 'marche-content-motion')}
      >
        <Reveal effect="marche-reveal-image-stretch" duration={500}>
          <div className="marche-wider-row">
            <VideoBlock videoId="297647668" />
          </div>
        </Reveal>
        <TextBlock headerText={<HeaderText />}>
          <p>
            メルカリがフリマアプリを越えて、 より日常生活の様々なシーンに入っていくことをイメージして、ロゴ自体が自由に、滑らかに、変形するモーションを設計しました。
          </p>
          <p>
            通常のシンプルなロゴアニメーションに加えて、メルカリが扱う商品やみなさまに提供したい価値など、様々なモチーフに自由に変形し、広がるメルカリの世界を表現していきます。
          </p>
        </TextBlock>
      </div>
    )
  }
}

export default Motion
