import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'
import Reveal from 'react-reveal/Reveal'

import ImageWrapper from './ImageWrapper'

const Example = () => (
  <StaticQuery
    query={graphql`
      query {
        bag: file(relativePath: { eq: "Example/bag.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1020, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cards: file(relativePath: { eq: "Example/cards.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1020, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        goods: file(relativePath: { eq: "Example/goods.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 680, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stationery: file(relativePath: { eq: "Example/stationery.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1020, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tshirtred: file(relativePath: { eq: "Example/tshirt-red.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 575, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tshirtwhite: file(relativePath: { eq: "Example/tshirt-white.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 425, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div
        className={classNames('marche-content-item', 'marche-content-example')}
      >
        <div
          className={classNames('marche-row', 'marche-content-example-item')}
        >
          <Reveal effect="marche-reveal-image-stretch" duration={500}>
            <Img
              fluid={data.stationery.childImageSharp.fluid}
              alt="original stationery"
              className="marche-content-example-item--stationery"
              critical
            />
            <Img
              fluid={data.goods.childImageSharp.fluid}
              alt="original goods"
              className="marche-content-example-item--goods"
              critical
            />
            <Img
              fluid={data.cards.childImageSharp.fluid}
              alt="business card"
              className="marche-content-example-item--cards"
              critical
            />
            <ImageWrapper ratio={[7, 5]}>
              <Img
                fluid={data.tshirtred.childImageSharp.fluid}
                alt="Tshirt red"
                className="marche-content-example-item--tshirt-red"
                critical
              />
              <Img
                fluid={data.tshirtwhite.childImageSharp.fluid}
                alt="Tshirt white"
                className="marche-content-example-item--tshirt-white"
                critical
              />
            </ImageWrapper>
            <Img
              fluid={data.bag.childImageSharp.fluid}
              alt="Tote bag"
              className="marche-content-example-item--bag"
              critical
            />
          </Reveal>
        </div>
      </div>
    )}
  />
)

export default Example
