import React from 'react'
import classNames from 'classnames'
import Reveal from 'react-reveal/Reveal'

import Slideshow from './Slideshow'
import TextBlock from './TextBlock'
import ImageWrapper from './ImageWrapper'
import VideoBlock from './VideoBlock'

import HeaderText from '../images/svg/text/head-logo.svg'

import DeadIdeasImage from '../images/svg/logo/logo-deadideas.svg'
import CharacterImageM from '../images/svg/logo/logo-character-m.svg'
import CharacterImageE from '../images/svg/logo/logo-character-e.svg'
import CharacterImageC from '../images/svg/logo/logo-character-c.svg'

import LogoSlides1 from '../images/svg/logo/logo-slide-sp1.svg'
import LogoSlides2 from '../images/svg/logo/logo-slide-sp2.svg'
import LogoSlides3 from '../images/svg/logo/logo-slide-sp3.svg'
import LogoSlides4 from '../images/svg/logo/logo-slide-sp4.svg'

class Logo extends React.Component {
  render() {
    return (
      <div className={classNames(
        'marche-content-item',
        'marche-content-logo',
      )}>
        <Slideshow>
          <LogoSlides1 />
          <LogoSlides2 />
          <LogoSlides3 />
          <LogoSlides4 />
        </Slideshow>
        <TextBlock headerText={<HeaderText />}>
          <p>
            これまでの、「箱から飛び出すワクワク」というアイデンティティを残しながら、要素を少なくしていくことで、より洗練されたロゴマークに仕上げ、メルカリの目指すオープンでフェアなマーケットを表現しています。実際に作ったプロトタイプは数百種類。試行錯誤を重ねて新しいロゴマークを決定しました。
          </p>
        </TextBlock>
        <div
          className={classNames(
            'marche-content-logo-deadideasimage',
            'marche-row',
          )}
        >
          <div
            className={classNames(
              'marche-column-fullwidth',
              'marche-image-wrapper',
            )}
          >
            <Reveal effect="marche-reveal-image-stretch" duration={500}>
              <div className="marche-svg-container-deadideas">
                <DeadIdeasImage className='marche-content-logo-deadIdeasImage' />
              </div>
            </Reveal>
          </div>
          <div
            className={classNames(
              'marche-column-fullwidth',
              'marche-image-caption',
            )}
          >
            <p>ロゴアイコンのボツ案</p>
          </div>
        </div>
        <div
          className={classNames(
            'marche-content-logo-imagecomparison',
            'marche-row',
          )}
        >
          <Reveal effect="marche-reveal-image-stretch" duration={500}>
            <div className="marche-column-fullwidth">
              <VideoBlock videoId="297856920" />
            </div>
          </Reveal>
          <div className={classNames(
            'marche-column-fullwidth',
            'marche-content-logo-typecomparison',
          )}>
            <ImageWrapper>
              <Reveal effect="marche-reveal-image-stretch" duration={500}>
                <div className={classNames('marche-image-wrapper')}>
                  <CharacterImageM />
                </div>
              </Reveal>
              <Reveal effect="marche-reveal-image-stretch" duration={500}>
                <div className={classNames('marche-image-wrapper')}>
                  <CharacterImageE />
                </div>
              </Reveal>
              <Reveal effect="marche-reveal-image-stretch" duration={500}>
                <div className={classNames('marche-image-wrapper')}>
                  <CharacterImageC />
                </div>
              </Reveal>
            </ImageWrapper>
          </div>
          <div
            className={classNames(
              'marche-column-fullwidth',
              'marche-image-caption',
            )}
          >
            <p>赤：新ロゴ&ensp;青：旧ロゴ</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Logo
