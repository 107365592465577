import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import scrollToComponent from 'react-scroll-to-component'
import BurgerMenu from 'react-hamburger-menu'
import classNames from 'classnames'

import Logotype from '../images/svg/assets/logotype-mercari.svg'
import TwIcon from '../images/svg/social/tw-icon-b.svg'
import FbIcon from '../images/svg/social/fb-icon-b.svg'

const scrollOptions = {
  offset: -80,
  align: 'top',
  duration: 500,
  ease: 'in-out-expo',
}

const fbUrl = 'https://www.facebook.com/sharer/sharer.php'
const fbOptions = ['u=https://design.mercari.com/jp/hello-new-logo/']

const twUrl = 'https://twitter.com/intent/tweet'
const twOptions = [
  'url=https://design.mercari.com/jp/hello-new-logo/',
  'via=mercari_jp',
  'related=mercari_jp',
  'hashtags=メルカリロゴ',
  'text=Hello new logo | メルカリデザイン',
]

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.twLink = encodeURI(`${twUrl}?${twOptions.join('&')}`)
    this.fbLink = encodeURI(`${fbUrl}?${fbOptions.join('&')}`)

    this.state = {
      open: false,
    }
    this.disableMenu = this.disableMenu.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.navClick = this.navClick.bind(this)
  }

  disableMenu(e) {
    if (this.state.open) this.setState({ open: false })
  }

  handleClick() {
    this.setState({
      open: !this.state.open,
    })
  }

  navClick(e) {
    if (this.state.open) this.handleClick()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.disableMenu)
  }

  render() {
    const { menu, hideLinks, showNewLogoLink } = this.props
    return (
      <Headroom className="marche-header-wrapper">
        <header
          className={classNames('marche-header', {
            'marche-header-active': this.state.open,
          })}
        >
          <div className="marche-header-inner">
            {showNewLogoLink ? (
              <Link to="/" className="marche-header-logotype">
                <Logotype />
              </Link>
            ) : (
              <a
                target="_blank"
                onClick={() => scrollToComponent(this, scrollOptions)}
                className="marche-header-logotype"
              >
                <Logotype />
              </a>
            )}
            {hideLinks ? null : (
              <React.Fragment>
                <div className="marche-header-burger">
                  <BurgerMenu
                    isOpen={this.state.open}
                    menuClicked={this.handleClick.bind(this)}
                    width={28}
                    height={20}
                    strokeWidth={3}
                    rotate={0}
                    color="#222"
                    borderRadius={0}
                    animationDuration={0.5}
                  />
                </div>
                <nav className="marche-header-nav" onClick={this.navClick}>
                  {menu.map((link, index) => (
                    <div className="marche-header-nav-item" key={index}>
                      <a
                        target="_blank"
                        onClick={() =>
                          scrollToComponent(link.ref, scrollOptions)
                        }
                      >
                        {link.svg}
                      </a>
                    </div>
                  ))}
                  <div className="marche-header-nav-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.twLink}
                    >
                      <TwIcon className="marche-header-nav-item-social" />
                    </a>
                  </div>
                  <div className="marche-header-nav-item">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.fbLink}
                    >
                      <FbIcon className="marche-header-nav-item-social" />
                    </a>
                  </div>
                </nav>
              </React.Fragment>
            )}
          </div>
        </header>
      </Headroom>
    )
  }
}

Header.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object),
  hideLinks: PropTypes.bool.isRequired,
  showNewLogoLink: PropTypes.bool.isRequired,
}

Header.defaultProps = {
  menu: [],
}

export default Header
