import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const intervalTimes = 5000

class Slideshow extends React.Component {
  constructor(props) {
    super(props)

    this.slideshow = React.createRef()

    this.state = {
      currentSlide: 0,
      imageWidth: 0,
      imageHeight: 0,
      timerId: null,
    }

    this.setDimensions = this.setDimensions.bind(this)
    this.nextSlide = this.nextSlide.bind(this)
    this.moveSlide = this.moveSlide.bind(this)
    this.navClicked = this.navClicked.bind(this)
    this.onWindowResize = this.onWindowResize.bind(this)
  }

  setDimensions() {
    const imageWrapper = this.slideshow.current
    const imageWidth = imageWrapper.offsetWidth
    const images = imageWrapper.childNodes
    const imageBox = images[0].viewBox.baseVal
    const imageHeight = (imageWidth * imageBox.height) / imageBox.width
    this.setState({ imageWidth, imageHeight })
  }

  nextSlide() {
    if (this.state.timerId !== null) clearInterval(this.state.timerId)
    this.moveSlide(this.state.currentSlide + 1)
    this.setState({ timerId: setInterval(this.nextSlide, intervalTimes) })
  }

  moveSlide(_slideNum) {
    let slideNum = _slideNum
    if (_slideNum >= this.props.children.length) {
      slideNum = 0
    } else if (_slideNum < 0) {
      slideNum = this.props.children.length - 1
    }
    this.setState({ currentSlide: slideNum })
  }

  navClicked(e) {
    const li = this.slideshow.current.parentNode.getElementsByClassName('marche-slideshow-nav-item')
    let index = -1
    for (let i = 0; i < li.length; i++) {
      if (e.target === li[i]) {
        index = i
        break
      }
    }
    if (index < 0) return
    if (this.state.timerId !== null) clearInterval(this.state.timerId)
    this.moveSlide(index)
    this.setState({ timerId: setInterval(this.nextSlide, intervalTimes) })
  }

  onWindowResize() {
    if (this.state.timerId !== null) clearInterval(this.state.timerId)
    this.setDimensions()
    this.setState({ timerId: setInterval(this.nextSlide, intervalTimes) })
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize)
    this.setDimensions()
    this.setState({ timerId: setInterval(this.nextSlide, intervalTimes) })
  }

  componentWillUnmount() {
    if (this.state.timerId !== null) clearInterval(this.state.timerId)
    this.setState({ timerId: null })
  }

  render() {
    const { currentSlide, imageWidth, imageHeight } = this.state
    const { children } = this.props
    return (
      <div className="marche-slideshow">
        <div className="marche-slideshow-window" ref={this.slideshow} style={{
          height: `${imageHeight}px`,
        }}>
          {children.map((image, index) => React.cloneElement(
            image,
            {
              key: index,
              style: { width: `${imageWidth}px`, height: `${imageHeight}px` },
              className: classNames(
                { 'marche-slideshow-images-current': index === currentSlide },
                { 'marche-slideshow-images-previous': index === currentSlide - 1 },
                { 'marche-slideshow-images-next': index === currentSlide + 1 || (index === 0 && currentSlide === children.length - 1) },
              ),
            },
          ))}
        </div>
        <div className="marche-slideshow-nav">
          <ul>
            {children.map((image, index) => {
              let elClass = 'marche-slideshow-nav-item'
              if (index === currentSlide) {
                elClass = `${elClass} marche-slideshow-nav-selected`
              }
              return <li className={elClass} onClick={this.navClicked} key={index} />
            })}
          </ul>
        </div>
      </div>
    )
  }
}

Slideshow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default Slideshow
