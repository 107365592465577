import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'
import Reveal from 'react-reveal/Reveal'

import TextBlockHeader from './TextBlockHeader'
import ImageWrapper from './ImageWrapper'

import HeaderText from '../images/svg/text/head-team.svg'

import TeamLogoMercari from '../images/svg/team/team-logo1.svg'
import TeamLogoTakram from '../images/svg/team/team-logo2.svg'
import TeamLogoParty from '../images/svg/team/team-logo3.svg'

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        teamOne: file(relativePath: { eq: "Team/team1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 510, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        teamTwo: file(relativePath: { eq: "Team/team2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 490, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className={classNames('marche-content-item', 'marche-content-team')}>
        <TextBlockHeader headerText={<HeaderText />} />
        <div
          className={classNames(
            'marche-content-team-collaborator',
            'marche-row',
          )}
        >
          <ImageWrapper columnNum="3">
            <a
              href="https://about.mercari.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="marche-content-team-collaborator-comp"
            >
              <TeamLogoMercari />
            </a>
            <a
              href="https://ja.takram.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="marche-content-team-collaborator-comp"
            >
              <TeamLogoTakram />
            </a>
            <a
              href="http://prty.nyc/"
              target="_blank"
              rel="noopener noreferrer"
              className="marche-content-team-collaborator-comp"
            >
              <TeamLogoParty />
            </a>
          </ImageWrapper>
        </div>
        <div className={classNames('marche-content-team-photos', 'marche-row')}>
          <ImageWrapper columnNum="2">
            <Reveal effect="marche-reveal-image-stretch" duration={500}>
              <Img
                fluid={data.teamOne.childImageSharp.fluid}
                alt="team photography 1"
                critical
              />
            </Reveal>
            <Reveal effect="marche-reveal-image-stretch" duration={500}>
              <Img
                fluid={data.teamTwo.childImageSharp.fluid}
                alt="team photography 2"
                critical
              />
            </Reveal>
          </ImageWrapper>
        </div>
      </div>
    )}
  />
)

export default Team
