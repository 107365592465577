import React from 'react'
import classNames from 'classnames'
import Player from '@vimeo/player'

import VideoPlayBtn from '../images/svg/assets/play-bt.svg'
import videoPlaceholder from '../images/HeroVideo/placeholder.png'

const videoUrl =
  'https://player.vimeo.com/video/296391368?background=1&loop=1&muted=0&autoplay=0'
const videoWidthMin = 480
const videoWidthMax = 1280

const videoStopOffset = 1000

class HeroVideo extends React.Component {
  constructor(props) {
    super(props)
    this.frame = React.createRef()
    this.player = null
    this.windowResize = this.windowResize.bind(this)
    this.pauseOnScroll = this.pauseOnScroll.bind(this)
    this.togglePlay = this.togglePlay.bind(this)
    this.resetPlay = this.resetPlay.bind(this)
    this.state = {
      playerReady: false,
      playerPlaying: false,
      playerStopped: true,
      windowWidth: 0,
      videoWidth: 0,
    }
  }

  windowResize() {
    const windowWidth = window.innerWidth
    let videoWidth = windowWidth
    if (windowWidth > videoWidthMax) {
      videoWidth = videoWidthMax
    } else if (windowWidth < videoWidthMin) {
      videoWidth = videoWidthMin
    }
    this.setState({ windowWidth, videoWidth })
  }

  togglePlay(play) {
    if (this.player === null || !this.state.playerReady) return
    this.player.getPaused().then(paused => {
      if ((!paused && play) || (paused && !play)) return
      if (paused && play) {
        this.player.play().then(() => {
          this.setState({ playerPlaying: true, playerStopped: false })
        })
      }
      if (!paused && !play) {
        this.player.pause().then(() => {
          this.setState({ playerPlaying: false })
        })
      }
    })
  }

  resetPlay() {
    if (this.player === null || !this.state.playerReady) return
    this.player.setCurrentTime(0).then(seconds => {
      if (seconds === 0) {
        this.setState({ playerStopped: true })
      }
    })
  }

  pauseOnScroll() {
    if (this.player === null || !this.state.playerReady) return
    const windowHeight = window.innerHeight
    const elementRect = this.frame.current
      .getElementsByTagName('iframe')[0]
      .getBoundingClientRect()
    let dist = 0
    if (elementRect.top > windowHeight) {
      dist = elementRect.top - windowHeight
    } else if (elementRect.top + elementRect.height < 0) {
      dist = Math.abs(elementRect.top + elementRect.height)
    } else {
      return
    }
    this.togglePlay(false)
    if (dist >= videoStopOffset && !this.state.stopped) this.resetPlay()
  }

  componentDidMount() {
    this.player = new Player(this.frame.current)
    this.player.on('pause', () => this.togglePlay(false))
    this.player.ready().then(() => this.setState({ playerReady: true }))
    window.addEventListener('scroll', this.pauseOnScroll)
    this.windowResize()
    window.addEventListener('resize', this.windowResize)
  }

  render() {
    const { playerPlaying, playerStopped, windowWidth, videoWidth } = this.state
    const videoHeight = (videoWidth / 16) * 9
    const wrapperMarginLeft = videoWidthMin < videoWidth
      ? 'auto' : `${(windowWidth - videoWidth) / 2}px`
    return (
      <div
        className={classNames(
          'marche-content-item',
          'marche-content-herovideo',
        )}
      >
        <div
          ref={this.frame}
          className={classNames(
            'marche-content-herovideo-wrapper',
            {
              'marche-content-herovideo-playing':
                !playerStopped && playerPlaying,
            },
            {
              'marche-content-herovideo-pausing':
                !playerStopped && !playerPlaying,
            },
          )}
          style={{
            width: `${videoWidth}px`,
            height: `${videoHeight}px`,
            marginLeft: wrapperMarginLeft,
          }}
        >
          <VideoPlayBtn
            className="marche-content-herovideo-btn"
            onClick={event => this.togglePlay(!playerPlaying)}
          />
          <img
            src={videoPlaceholder}
            alt="Transformation video of our logo"
            className="marche-content-herovideo-placeholder"
            onClick={event => this.togglePlay(!playerPlaying)}
            style={{ height: `${videoHeight}px` }}
          />
          <iframe
            src={videoUrl}
            width="1920"
            height="1080"
            frameBorder="0"
            autopause="0"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </div>
    )
  }
}

export default HeroVideo
