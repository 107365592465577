import React from 'react'
import classNames from 'classnames'

import TextBlock from './TextBlock'
import HeaderText from '../images/svg/text/head-rebrand.svg'
import DateSVG from '../images/svg/assets/date.svg'

class Rebrand extends React.Component {
  render() {
    return (
      <div className={classNames(
        'marche-content-item',
        'marche-content-rebrand',
      )}>
        <TextBlock headerText={<HeaderText />}>
          <div className="marche-content-rebrand-tagline">
            <h2>新しいメルカリ、はじまる</h2>
          </div>
          <p>
            みなさまにご利用いただいて、メルカリは6年目を迎えました。
          </p>
          <p>
            創業以来、世界的なマーケットプレイスの実現を掲げ、フリマアプリ「メルカリ」の地道なアップデートを続けています。
          </p>
          <p>
            もっとシームレスに、便利に、快適に。今までよりもなめらかな生活の後押しをするプラットフォームを目指し、メルカリは、これまでのメルカリを超え続けていきます。
          </p>
          <p>
            そんな未来への強い意志を込めて、ロゴの変更を行いました。よりシンプルで、フレンドリーな新しいロゴでお目にかかれることを楽しみにしています。新しいメルカリは、あなたの生活とともに。
          </p>
          <DateSVG className='marche-content-rebrand-date' />
        </TextBlock>
      </div>
    )
  }
}

export default Rebrand
