import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'

const Head = () => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            url
            domainRoot
            description
            fbAdmins
            twCard
            ogImage
            ogImageWidth
            ogImageHeight
          }
        }
      }
    `}
    render={data => {
      const { siteMetadata } = data.site
      return (
        <Helmet
          title={siteMetadata.title}
          meta={[
            { name: 'description', content: siteMetadata.description },
            { property: 'og:title', content: siteMetadata.title },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: siteMetadata.url },
            {
              property: 'og:image',
              content:
                `${siteMetadata.domainRoot}${withPrefix(siteMetadata.ogImage)}`,
            },
            { property: 'og:image:width', content: siteMetadata.ogImageWidth },
            {
              property: 'og:image:height',
              content: siteMetadata.ogImageHeight,
            },
            { property: 'fb:admins', content: siteMetadata.fbAdmins },
            { property: 'og:description', content: siteMetadata.description },
            { name: 'twitter:card', content: siteMetadata.twCard },
            { name: 'twitter:title', content: siteMetadata.title },
            { name: 'twitter:description', content: siteMetadata.description },
            {
              name: 'twitter:image',
              content:
                `${siteMetadata.domainRoot}${withPrefix(siteMetadata.ogImage)}`,
            },
            {
              itemprop: 'image',
              content:
                `${siteMetadata.domainRoot}${withPrefix(siteMetadata.ogImage)}`,
            },
          ]}
        >
          <html lang="ja" />
        </Helmet>
      )
    }}
  />
)

export default Head
