import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Reveal from 'react-reveal/Reveal'

import TextBlockHeader from './TextBlockHeader'

const TextBlock = props => (
  <div className={classNames(
    'marche-text-block',
    'marche-row',
  )}>
    <TextBlockHeader headerText={props.headerText} />
    <Reveal effect="marche-reveal-fade" duraction={500}>
      <div className="marche-text-block-body">
          {props.children}
      </div>
    </Reveal>
  </div>
)

TextBlock.propTypes = {
  headerText: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

export default TextBlock
