import React from 'react'
import PropTypes from 'prop-types'

const marcheDeviceLarge = '800'
const marcheVerticalGutter = '20'
const marcheVerticalGutterSmall = '12'

class ImageWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef()

    if (props.ratio && props.ratio.length === this.props.children.length) {
      let total = 0
      for (let i = 0; i < props.ratio.length; i++) {
        total += props.ratio[i]
      }
      this.state = {
        ratio: props.ratio,
        ratioTotal: total,
        columnNum: props.children.length,
      }
    } else {
      this.state = {
        columnNum: props.children.length,
      }
    }

    this.setDimensions = this.setDimensions.bind(this)
  }

  setDimensions() {
    const imageWrapper = this.wrapper.current
    const images = imageWrapper.childNodes
    let verticalGutter = marcheVerticalGutter
    if (window.innerWidth < marcheDeviceLarge) verticalGutter = marcheVerticalGutterSmall
    if (!this.props.ratio) {
      const imageWidth = (imageWrapper.offsetWidth - verticalGutter * (this.state.columnNum - 1)) / this.state.columnNum
      for (let i = 0; i < images.length; i++) {
        images[i].style.width = `${imageWidth}px`
      }
    } else {
      const imageElWidth = (imageWrapper.offsetWidth - verticalGutter * (this.state.columnNum - 1)) / this.state.ratioTotal
      for (let i = 0; i < images.length; i++) {
        images[i].style.width = `${imageElWidth * this.state.ratio[i]}px`
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.setDimensions)
    this.setDimensions()
  }

  render() {
    return (
      <div className='marche-imageWrapper' ref={this.wrapper}>
        {this.props.children}
      </div>
    )
  }
}

ImageWrapper.propTypes = {
  ratio: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default ImageWrapper
