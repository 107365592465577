import React from 'react'
import classNames from 'classnames'
import Reveal from 'react-reveal/Reveal'

import TextBlock from './TextBlock'
import HeaderText from '../images/svg/text/head-color.svg'

import colorIconVariations from '../images/Colors/motion-icon.jpg'
import ColorHexRed from '../images/svg/color/hex1.svg'
import ColorHexWhite from '../images/svg/color/hex2.svg'
import ColorHexBlue from '../images/svg/color/hex3.svg'

const colors = [
  { hex: <ColorHexRed />, name: 'red' },
  { hex: <ColorHexWhite />, name: 'white' },
  { hex: <ColorHexBlue />, name: 'blue' },
]

const subcolors = [
  'green',
  'purple',
  'orange',
  'turmeric',
  'pink',
  'blue',
  'emerald',
  'navy',
]

class Color extends React.Component {
  render() {
    return (
      <div
        className={classNames('marche-content-item', 'marche-content-color')}
      >
        <div
          className={classNames(
            'marche-content-color-colorbar',
            'marche-wider-row',
          )}
        >
          <Reveal effect="marche-reveal-stretch" duration={500}>
            {colors.map((color, index) => (
              <div
                key={index}
                className={classNames(
                  'marche-content-color-colorbar-item',
                  `marche-content-color-colorbar-item--${color.name}`,
                )}
              >
                <p className="marche-content-color-colorbar-item-title">
                  {color.hex}
                </p>
              </div>
            ))}
          </Reveal>
        </div>
        <div
          className={classNames(
            'marche-content-color-subcolorbar',
            'marche-wider-row',
          )}
        >
          <Reveal effect="marche-reveal-stretch" duration={500}>
            {subcolors.map((color, index) => (
              <div
                key={index}
                className={classNames(
                  'marche-content-color-subcolorbar-item',
                  `marche-content-color-subcolorbar-item--${color}`,
                )}
              />
            ))}
          </Reveal>
        </div>
        <TextBlock headerText={<HeaderText />}>
          <p>
            一目でメルカリだとわかるように、クリアでビビッドなカラーに変更しました。また、新しいデザインシステムを採用し、基本のメルカリレッドをベースに様々なカラーを用いることができます。
          </p>
          <p>
            メディア・キャンペーンなどでは、メルカリのなんでも売れる/買えるワクワクを表現するために、カラーやイラストで突き抜けた印象を。アプリではややトーンダウンした色合いを用いて、マーケットの公平感を。目的に応じて柔軟に対応できるように設計されています。
          </p>
        </TextBlock>
        <div className={classNames('marche-content-color-items', 'marche-row')}>
          <Reveal effect="marche-reveal-image-stretch" duration={500}>
            <img src={colorIconVariations} alt="icon variations" />
          </Reveal>
        </div>
      </div>
    )
  }
}
export default Color
