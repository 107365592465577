import React from 'react'
import classNames from 'classnames'

import TextBlockHeader from './TextBlockHeader'

import HeaderText from '../images/svg/text/head-workwithus.svg'

import MercariLogo from '../images/svg/assets/logotype-mercari2.svg'
import MerpayLogo from '../images/svg/assets/logotype-merpay.svg'
import Arrow from '../images/svg/assets/arrow.svg'

class WorkWithUs extends React.Component {
  render() {
    return (
      <div className={classNames(
        'marche-content-item',
        'marche-content-workwithus',
      )}>
        <TextBlockHeader headerText={<HeaderText />}></TextBlockHeader>
        <div className={classNames(
          'marche-content-workwithus-apply',
          'marche-row',
        )}>
          <div className='marche-content-workwithus-apply-category'>
            <a href='https://careers.mercari.com/jp/search-jobs?cat=mercari-jp-app&dep=design'
              target='_blank' rel='noopener noreferrer'>
              <MercariLogo className='marche-content-workwithus-apply-category-logotype' />
              <div className='marche-apply-position-text'>
                <p className='marche-apply-position-text-title'>
                  メルカリのデザイナーに応募する
                </p>
                <p className='marche-apply-position-text-arrow'>
                  <Arrow />
                </p>
              </div>
            </a>
          </div>
          <div className='marche-content-workwithus-apply-category'>
            <a href='https://careers.mercari.com/jp/search-jobs?cat=merpay&dep=design'
              target='_blank' rel='noopener noreferrer'>
              <MerpayLogo className='marche-content-workwithus-apply-category-logotype' />
              <div className='marche-apply-position-text'>
                <p className='marche-apply-position-text-title'>
                  メルペイのデザイナーに応募する
                </p>
                <p className='marche-apply-position-text-arrow'>
                  <Arrow />
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default WorkWithUs
