import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Player from '@vimeo/player'

const videoStopOffset = 600

class VideoBlock extends React.Component {
  constructor(props) {
    super(props)
    this.iframe = React.createRef()
    this.triggerOnScroll = this.triggerOnScroll.bind(this)
    this.state = {
      player: null,
      stopped: true,
    }
  }

  triggerOnScroll() {
    const { player } = this.state
    if (player === null) return

    const windowHeight = window.innerHeight
    const elementRect = this.iframe.current.getBoundingClientRect()
    let dist = 0
    if (elementRect.top > windowHeight) {
      dist = elementRect.top - windowHeight
    } else if ((elementRect.top + elementRect.height) < 0) {
      dist = Math.abs(elementRect.top + elementRect.height)
    }
    player.getPaused().then(paused => {
      if (dist <= 0 && !paused && !this.state.stopped) return
      if (dist > videoStopOffset && this.state.stopped) return

      if (dist <= 0) {
        player.play().then(() => {
          this.setState({ stopped: false })
        })
      } else if (dist > videoStopOffset) {
        player.setCurrentTime(0).then(seconds => {
          player.pause().then(() => {
            this.setState({ stopped: true })
          })
        })
      } else {
        player.pause()
      }
    })
  }

  componentDidMount() {
    const player = new Player(this.iframe.current)
    player.ready().then(() => {
      this.setState({ player })
    })
    window.addEventListener('scroll', this.triggerOnScroll)
  }

  render() {
    return (
      <div className={classNames(
        'marche-content-videoblock',
        'marche-video-block',
      )}>
        <iframe
          ref={this.iframe}
          src={`https://player.vimeo.com/video/${this.props.videoId}?background=1&loop=1&autoplay=0&autopause=0`}
          width="1920"
          height="1080"
          frameBorder="0"
          allowFullScreen />
      </div>
    )
  }
}

VideoBlock.propTypes = {
  videoId: PropTypes.string.isRequired,
}

export default VideoBlock
